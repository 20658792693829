<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar title="资讯中心" />
    </div> -->
    <!-- tab栏 -->
    <div class="information">
      <van-tabs
        v-model="riKind"
        @click="onClick"
        color="#1989fa"
        style="border: 5px solid rgb(247, 247, 247)"
      >
        <van-tab title="在职" name="1"> </van-tab>
        <van-tab title="离职" name="2"> </van-tab>
      </van-tabs>
      <div v-for="item in list" :key="item.index">
        <div class="list">
          <div class="title">姓名: {{ item.Name }}</div>
          <div class="flex">
            <span>身份证号: {{ item.IdCard }}</span>
          </div>
          <div class="flex">
            <span>手机号: {{ item.Mobile }}</span>
            <span v-if="item.EduName != ''">学历: {{ item.EduName }}</span>
          </div>
          <div
            v-if="item.Post != null && item.Post != ''"
            style="padding: 10px 20px"
          >
            岗位: {{ item.Post }}
          </div>
          <div class="flex">
            <span>入职日期: {{ parseTime(item.InDate) }}</span>
          </div>

          <div class="flex" v-if="item.Status == 2">
            <span>离职日期: {{ parseTime(item.LeaveDate) }}</span>
          </div>
          <div class="flex">
            <span>登录次数: {{ item.LoginCount }}</span>
          </div>
          <div class="flex">
            <span>最后登录时间: {{ parseTime(item.LoginTime) }}</span>
          </div>

          <div
            class="Status"
            style="background-color: #e6776f"
            v-if="item.Status == 2"
          >
            离职
          </div>
          <div
            class="Status"
            style="background-color: #02c08f"
            v-if="item.Status == 1"
          >
            在职
          </div>
          <div
            style="
              display: inline-block;
              width: 100%;
              text-align: right;
              margin-bottom: 5px;
            "
            class="ContentB"
          >
            <span class="centertime" style="color: #333">
              <van-button
                type="info"
                style="
                  width: 80px;
                  font-size: 13px;
                  border-radius: 5px;
                  margin-right: 5px;
                "
                @click="updata(item)"
                size="small"
                >编辑</van-button
              >
              <van-button
                type="warning"
                v-if="item.Status == 2"
                style="
                  width: 80px;
                  font-size: 13px;
                  border-radius: 5px;
                  margin-right: 5px;
                "
                @click="inDateClick(item)"
                size="small"
                >重新入职</van-button
              >
              <van-button
                type="warning"
                v-if="item.Status == 1"
                style="
                  width: 80px;
                  font-size: 13px;
                  border-radius: 5px;
                  margin-right: 5px;
                "
                @click="leaveDateClick(item)"
                size="small"
                >离职</van-button
              >
              <van-button
                type="danger"
                v-if="item.Status == 1"
                style="
                  width: 80px;
                  font-size: 13px;
                  border-radius: 5px;
                  margin-right: 5px;
                "
                @click="dangerClick(item)"
                size="small"
                >删除</van-button
              >
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="center"
          v-for="item in list"
          :key="item.index">
          <div class="Content"
            style="padding: 8px 10px; line-height: 30px">
            <div>
              <div style="font-size: 18px; color: #333;display: flex;justify-content: space-between;">
                <div style="padding-right:10px;">
                  <van-icon name="manager" /> {{ item.Name }}</div>
                <div>
                  <van-icon name="phone" /> {{ item.Mobile }}</div>
              </div>

              <div class="centertime"
                style="font-size: 18px; color: #333">
                身份证号：{{ item.IdCard }}
              </div>
              <div class="centertime"
                style="font-size: 18px; color: #333">
                岗位：{{ item.Post }} </div>
              <div class="centertime"
                style="font-size: 18px; color: #333">
                入职日期：{{ item.InDate }}
              </div>
              <div class="centertime"
                style="font-size: 18px; color: #333">
                最后登录时间：{{ item.LoginTime }} </div>
              <div class="centertime"
                style="font-size: 18px; color: #333">
                登录次数：{{ item.LoginCount }}
              </div>
              <div class="centertime"
                v-if="item.Status == 2"
                style="font-size: 18px; color: #333">
                离职日期：{{ item.LeaveDate }}
              </div>
            </div>
            
          </div>
        </div> -->

      <div style="position: fixed; bottom: 15px; width: 100%">
        <div style="width: 100%; text-align: center">
          <van-button
            class="button"
            @click="postfrom(1)"
            style="width: 95%; border-radius: 5px"
            type="info"
            >新增</van-button
          >
        </div>
      </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { HInDate, HLeaveDate, HDeleteHotelStaff } from "@/api/RealInfo";
import { HGetMyStaffList } from "@/api/shop";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from "vant";
import { Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      riKind: 0, //tab栏
      list: {},
      listfrom: {
        page: 0,
        limit: 10,
        hotelId: "",
        status: 1,
      }, //查询传参
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      Status: false,
      jiezhongTime: false,
      minDate: new Date(2018, 0, 1),
      maxDate: new Date(2030, 10, 1),
      dataform: {},
      jiezhognDate: "",
      yimiaolist: [
        { id: "1", Name: "未接种" },
        { id: "2", Name: "第一针" },
        { id: "3", Name: "第二针" },
        { id: "4", Name: "第三针" },
      ],
      datafrom: {},
      hslist: {},
    };
  },
  filters: {
    capitalizeTime: function (value) {
      if (!value) return "";
      let gstime = value.substring(0, 10);
      return gstime;
    },
  },
  methods: {
    // 获取从业人员列表
    getList() {
      this.listfrom.openID = getOpenId();
      this.listfrom.hotelId = this.$route.params.hotelId;
      HGetMyStaffList(this.listfrom).then((res) => {
        if (res.data.code == 0) {
          if (res.data.count == 0) {
            this.finished = true;
            this.list = {};
          } else {
            this.list = res.data.data;
            this.total = res.data.count;
            this.finished = true;
          }
        }
      });
    },
    // 新增
    postfrom() {
      var url =
        "/hotels/myHotelsList/myStaffList/addStaff/0/" +
        this.$route.params.hotelId +
        "/0";
      this.$router.push(url);
    },
    //编辑从业人员信息
    updata(val) {
      console.log(val);
      var url =
        "/hotels/myHotelsList/myStaffList/addStaff/1/" +
        val.HotelId +
        "/" +
        val.StaffId;
      this.$router.push(url);
    },
    //详情
    fanqixiangqing(ID) {
      console.log(ID);
      this.$router.push({
        path: "/register/fangyi/myFangKong/fkFanQi/mkfqXiangQing/" + ID,
      });
    },
    // 重新入职
    inDateClick: function (val) {
      Dialog.confirm({
        title: "提示",
        message: "确定要重新入职吗",
      })
        .then(() => {
          console.log("确认");
          HInDate({
            HotelId: this.$route.params.hotelId,
            StaffId: val.StaffNum,
            OpenID: getOpenId(),
          })
            .then((res) => {
              if (res.data.code == 0) {
                Toast.success("操作成功");
                this.getList();
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          console.log("取消");
          // on cancel
        });
    },
    // 离职
    leaveDateClick: function (val) {
      Dialog.confirm({
        title: "提示",
        message: "确定要离职吗",
      })
        .then(() => {
          console.log("确认");
          HLeaveDate({
            HotelId: this.$route.params.hotelId,
            StaffId: val.StaffNum,
            OpenID: getOpenId(),
          })
            .then((res) => {
              if (res.data.code == 0) {
                Toast.success("操作成功");
                this.getList();
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          console.log("取消");
          // on cancel
        });
    },
    // 删除
    dangerClick: function (val) {
      Dialog.confirm({
        title: "提示",
        message: "确定要删除该员工吗",
      })
        .then(() => {
          console.log("确认");
          HDeleteHotelStaff({
            HotelId: this.$route.params.hotelId,
            StaffId: val.StaffId,
            OpenID: getOpenId(),
          })
            .then((res) => {
              if (res.data.code == 0) {
                Toast.success("操作成功");
                this.getList();
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {
          console.log("取消");
          // on cancel
        });
    },
    // 疫苗选择器
    onStatus(val) {
      this.datafrom.Status = val.id;
      this.datafrom.StatusDesc = val.Name;
      this.datafrom.VaccinateTime = "";
      this.Status = false;
    },
    onjiezhongFend(val) {
      //接种时间 确认
      this.datafrom.VaccinateTime = this.dateformat(val);
      this.jiezhongTime = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    // 下拉刷新
    onLoad() {
      console.log(123);
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    onClick(e) {
      this.list = {};
      this.listfrom.status = e;
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.getList();
    });
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.van-tabs__content {
  border-top: 10px solid rgb(247, 247, 247);
}
.van-tabs__wrap--scrollable {
  margin-bottom: 5px;
}

.list {
  position: relative;
  width: 90%;
  margin: 5%;
  background: #fff;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
  box-shadow: 0 0 5px #dadada;
}

.list .title {
  padding: 10px 20px 5px;
  font-weight: 700;
  font-size: 15px;
  color: #000;
  letter-spacing: 2px;
}

.list .flex {
  display: flex;
  font-size: 14px;
  color: #333;
  padding: 10px 20px 5px;
  justify-content: space-between;
}

.list .flex text:nth-child(1) {
  width: 40%;
}

.list .flex text:nth-child(2) {
  width: 60%;
}

.list .Status {
  position: absolute;
  top: 0px;
  right: 10px;
  /* background: #4b8cff; */
  font-size: 16px;
  padding: 5px 0px;
  color: #fff;
  width: 62px;
  text-align: center;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>